import React from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/icon-Logo.png";
import axios from "axios";

const Sidebar = ({ togglesideBar, toggle }) => {
  const location = useLocation();
  const sideLinks = [`blogs`, `news`,'addimages'];
  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <div>
      <div className={`sidebar ${toggle && "show"}`}>
        <div className="toggle-btn close" onClick={togglesideBar}>
          <FaTimes />
        </div>
        <a href="/" className="logo">
          <img src={logo} alt="img" />
          <span className="fs-4"> Blockcoaster</span>
        </a>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          {sideLinks.map((data, index) => {
            return (
              <li key={index}>
                <Link
                  to={`/${data}`}
                  activeClassName="active"
                  className={`nav-link link-body-emphasis ${
                    isActive(`/${data}`) ? "active" : ""
                  }`}
                >
                  <svg className="bi pe-none me-2" width={16} height={16}>
                    <use xlinkHref="#speedometer2" />
                  </svg>
                  {data.charAt(0).toUpperCase() + data.slice(1)}
                </Link>
              </li>
            );
          })}
        </ul>
        <hr />
      </div>
    </div>
  );
};

export default Sidebar;
