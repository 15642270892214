import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import { FaPencilAlt } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "./environment";

const Blog = () => {
  const [toggle, settoggle] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const navigate = useNavigate();
  const togglesideBar = () => {
    settoggle(!toggle);
  };
  const handleUpdateClick = async (blogId) => {
    const token = localStorage.getItem("token");
    try {
      var confirmation = window.confirm("Are you sure you want to proceed?");
      if (confirmation) {
        const res = await axios.delete(`${apiUrl}/blog/delete/${blogId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(res);
        window.location.reload();
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editButton = async (data) => {
    navigate("/editblogs", { state: data });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${apiUrl}/blog/getAllBlogs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status && response.data.data) {
          const shortenedBlogList = response.data.data;
          setBlogList(shortenedBlogList);
        } else {
          console.error("Invalid API response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Sidebar toggle={toggle} togglesideBar={togglesideBar} />
      <div className={`content ${toggle && "show"}`}>
        <div className="container">
          <div className="header-box">
            <div className="toggle-btn" onClick={togglesideBar}>
              <i className="bi bi-list"></i>
            </div>
            <div>
              <strong>Blog List</strong>
            </div>
            <div className="dropdown">
              <a
                href="##"
                className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="https://github.com/mdo.png"
                  alt="error"
                  width={32}
                  height={32}
                  className="rounded-circle me-2"
                />
              </a>
              <ul className="dropdown-menu text-small shadow">
                <li>
                  <a className="dropdown-item" href="##">
                    New project...
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="##">
                    Settings
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#@">
                    Profile
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="##">
                    Sign out
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Link className="button-styles m-1" to="/addblogs">
              Add Blogs{" "}
            </Link>
          </div>
          <div className="table-responsive">
            <div>
              <div className="table-responsive table-box">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-head">
                      <th>Blog Id</th>
                      <th>Title</th>
                      <th className="w-25">Description</th>
                      <th>Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blogList?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1} </td>
                          <td>{data.title.slice(0, 25)} ...</td>
                          <td>
                            {" "}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            />
                          </td>
                          <td>
                            {" "}
                            {data.blogImg.map((image, index) => (
                              <img
                                key={index}
                                width={70}
                                src={`${apiUrl}/image?pathName=BLOGS_IMAGE&imageName=${image}`}
                                alt={`Blog ${index + 1}`}
                                className="blog-image mx-1"
                              />
                            ))}
                            -
                          </td>
                          <td>
                            <div
                              className="button-styles mx-1"
                              onClick={() => editButton(data)}
                            >
                              <FaPencilAlt />
                            </div>
                            <button
                              className="button-styles mx-1"
                              onClick={() => handleUpdateClick(data.blogId)}
                            >
                              <RiDeleteBin5Line />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
