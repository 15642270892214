import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/login";
import News from "./components/news";
import Blog from "./components/blog";
import Landing from "./components/landing";
import AddNews from "./components/addNews";
import AddBlogs from "./components/addBlogs";
import EditBlogs from "./components/editBlogs";
import EditNews from "./components/editNews";
import AddImages from "./components/addImages";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route exact path="/" element={<Landing />} /> */}
          <Route exact path="/" element={<Login />} />
          <Route exact path="/news" element={<News />} />
          <Route exact path="/addnews" element={<AddNews />} />
          <Route exact path="/blogs" element={<Blog />} />
          <Route exact path="/addblogs" element={<AddBlogs />} />
          <Route exact path="/editblogs" element={<EditBlogs />} />
          <Route exact path="/editnews" element={<EditNews />} />
          <Route exact path="/addimages" element={<AddImages />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
