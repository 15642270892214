import React, { useEffect, useState } from 'react'
import Sidebar from "./sidebar";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import { apiUrl } from './environment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { RotatingLines } from 'react-loader-spinner';

const AddImages = () => {

    const [toggle, settoggle] = useState(false);
    const [files , setFiles] = useState([])
    const [allImages , setAllImages] = useState([])
    const [loader, setLoader] = useState(false)
    const togglesideBar = () => {
      settoggle(!toggle);
    };
    const handleChange = async (e) => {
        const {name , type } = e.target 
        if (type === "file") {
            const filesArray = e.target.files;
          setFiles(filesArray)
            ;
          }
    }
    const uploadImages = async (e) => {
      setLoader(true)
        try {
            e.preventDefault()   
             const apiFormData = new FormData();
             const imagesArray = Array.isArray(files)
             ? files
             : [files];
   
           for (let i = 0; i < files.length; i++) {
             apiFormData.append("blogImgs", files[i]);
           }
           const api = `${apiUrl}/blog/uploadImage`;
           const response = await axios.post(api, apiFormData);
           console.log(response)
           if (response.status==201) {
            toast.success('Images added Successfully')
            getImages()
           }
        } catch (error) {
            console.log(error)
        }
        setLoader(false)

    }
    const getImages = async () => {
        const resp = await axios(`${apiUrl}/blog/getAllImages`)
        console.log(resp.data.getImages)
        setAllImages(resp.data.getImages)
    }
    const handleCopy = async () => {
      toast.success('Image Link Copied')
    }
    useEffect(() => {
      getImages()
    }, [])
    
  return (
    <>
      <Sidebar toggle={toggle} togglesideBar={togglesideBar} />
      <div className={`content ${toggle && "show"}`}>
        <div className="container">
          <div className="header-box">
            <div className="toggle-btn" onClick={togglesideBar}>
              <i className="bi bi-list"></i>
            </div>
        <div><strong>Add images</strong></div>
            <div className="dropdown">
              <a
                href="#"
                className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="https://github.com/mdo.png"
                  alt="error"
                  width={32}
                  height={32}
                  className="rounded-circle me-2"
                />
              </a>
              <ul className="dropdown-menu text-small shadow">
                <li>
                  <a className="dropdown-item" href="#">
                    New project...
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Settings
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Profile
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Sign out
                  </a>
                </li>
              </ul>
            </div>
          </div>
          </div>
          {loader && <div className="overlay"></div>}
          <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={loader}
                />
    <form onSubmit={uploadImages}>
        <div>

  <label className="my-1">Image :</label>
                    <input
                      className="form-control"
                      type="file"
                      name="image"
                      onChange={handleChange}
                      accept="image/*"
                      multiple
                    />

    </div>
    <button type="submit" className='button-styles'>Upload Images</button>
    </form>
{
    allImages.map((data,index)=>{
            return(
                
            <>
            {data.imageUrl.map((image, index) => (
              <>
              <CopyToClipboard
              text={`${apiUrl}/image?pathName=SAVE_BLOGS_IMAGE&imageName=${image}`}
              onCopy={handleCopy}
              >

                            <img
                              key={index}
                              width={100}
                              src={`${apiUrl}/image?pathName=SAVE_BLOGS_IMAGE&imageName=${image}`}
                              alt={`Blog ${index + 1}`}
                              className="addImage mx-1"
                              />
                              </CopyToClipboard>

                          </>
                          ))} 
            </>
            )
    })
}
    </div>

<ToastContainer position='top-right'/>
    </>
  )
}

export default AddImages
