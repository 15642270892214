import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./sidebar";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import { RotatingLines } from "react-loader-spinner";
import { apiUrl } from "./environment";

const EditBlogs = () => {
  const location = useLocation();
  const { state } = location;
  console.log(state);
  const [toggle, settoggle] = useState(false);
  const togglesideBar = () => {
    settoggle(!toggle);
  };
  const [loader, setLoader] = useState(false);
  const [content, setContent] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    image: [],
    subtitle: "",
  });
  const editor = useRef(null);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, type } = e.target;

    if (type === "file") {
      const filesArray = e.target.files;
      setFormData((prevData) => ({
        ...prevData,
        [name]: filesArray,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.value,
      }));
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.title.trim()) {
      newErrors.title = "Title is required";
    }

    if (!content.trim()) {
      newErrors.content = "Content is required";
    }
    if (!formData.image || formData.image.length === 0) {
      newErrors.image = "At least one image is required";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoader(true);

        const apiFormData = new FormData();
        apiFormData.append("title", formData.title);
        apiFormData.append("subtitle", formData.subtitle);
        apiFormData.append("description", content);
        const imagesArray = Array.isArray(formData.image)
          ? formData.image
          : [formData.image];

        for (let i = 0; i < formData.image.length; i++) {
          apiFormData.append("blogImg", formData.image[i]);
        }
        console.log(imagesArray);
        console.log(state.blogId);
        const apiLink = `${apiUrl}/blog/update/${state.blogId}`;
        const token = localStorage.getItem("token");
        const response = await axios.put(apiLink, apiFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success("Blog Updated successfully");
        navigate("/blogs");
        console.log("API Response***:", response.data.message);
        setFormData({
          title: "",
          content: "",
          image: [],
        });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      setLoader(false);
    }
  };
  useEffect(() => {
    const authToken = localStorage.getItem("token");
    console.log("authToken", authToken);
  }, []);
  useEffect(() => {
    if (state !== null) {
      setContent(state.description);
      setFormData({
        subtitle: state.subtitle,
        title: state.title,
        image: state.imageUrl,
      });
    }
  }, [state]);

  return (
    <>
      <Sidebar toggle={toggle} togglesideBar={togglesideBar} />
      <div className={`content ${toggle && "show"}`}>
        <div className="container">
          <div className="header-box">
            <div className="toggle-btn" onClick={togglesideBar}>
              <i className="bi bi-list"></i>
            </div>
            <div>
              <strong>Edit Blogs </strong>
            </div>
            <div className="dropdown">
              <a
                href="/"
                className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="https://github.com/mdo.png"
                  alt="error"
                  width={32}
                  height={32}
                  className="rounded-circle me-2"
                />
              </a>
              <ul className="dropdown-menu text-small shadow">
                <li>
                  <a className="dropdown-item" href="/">
                    New project...
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/">
                    Settings
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/">
                    Profile
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/">
                    Sign out
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row ">
            {loader && <div className="overlay"></div>}
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={loader}
            />
            <div className="col-md-6 col-12 mx-md-auto py-3">
              <div className="text-center">
                <form className="" onSubmit={handleSubmit}>
                  <div className="">
                    <label className="my-1">Title :</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                    />

                    {errors.title && <p className="error">{errors.title}</p>}
                  </div>
                  <div className="">
                    <label className="my-1">Subtitle :</label>
                    <input
                      type="text"
                      className="form-control"
                      name="subtitle"
                      value={formData.subtitle}
                      onChange={handleChange}
                    />

                    {errors.subtitle && (
                      <p className="error">{errors.subtitle}</p>
                    )}
                  </div>
                  <div className="">
                    <label className="my-1">Description :</label>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                      onChange={(newContent) => {}}
                    />

                    {errors.content && (
                      <p className="error">{errors.content}</p>
                    )}
                  </div>

                  <div className="">
                    {" "}
                    <label className="my-1">Image :</label>
                    <input
                      className="form-control"
                      type="file"
                      name="image"
                      onChange={handleChange}
                      accept="image/*"
                      multiple
                    />
                    {errors.image && <p className="error">{errors.image}</p>}
                  </div>

                  <br />
                  <div>
                    <button className="button-styles" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBlogs;
